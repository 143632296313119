<template>
  <v-navigation-drawer width="400" v-model="localDrawer" right temporary app>
    <v-list-item :class="localAula.color">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">{{
          localAula.tipo_de_aula
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Professor (a)</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.professor?.nome }}
        </p>
        <v-divider></v-divider>
        <v-list-item-title>Data</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.data_formatada }}
          {{ " - " }}
          {{ localAula.horario ? localAula.horario.descricao : "[- - -]" }}
        </p>
        <div v-for="(item, index) of $constants.listCamposAulasLancadas" :key="index">
          <div v-if="index !== 'campos_de_experiencias'">
            <v-list-item-title v-if="localAula[index]" class="mt-4 mb-5">{{
              item
            }}</v-list-item-title>
            <div v-if="localAula[index]" class="font-weight-light text-justify">
              <div
                v-html="formatTexto(localAula[index]) ? formatTexto(localAula[index]) : '- - -'"
              ></div>
            </div>
          </div>
          <div v-else>
            <v-list-item-title v-if="localAula[index]" class="mt-4 mb-2">{{
              item
            }}</v-list-item-title>
            <div v-for="(el, index) of localAula[index]" :key="index">
              <p v-if="el" :key="index" class="font-weight-light text-justify">•{{ el }}</p>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <!-- <v-list-item-title class="mt-4">Metodología</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula.metodologia }}
        </p> -->
        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Situação</v-list-item-title>
        <v-list-item-title>
          <v-chip label :color="localAula.color">
            {{ localAula.descricaoSituacao }}
          </v-chip>
        </v-list-item-title>
        <v-list-item-title class="mt-4">Motivo</v-list-item-title>
        <p class="font-weight-light">
          {{ localAula.motivo ? localAula.motivo : "Sem motivo." }}
        </p>
      </v-list-item-content>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    aula: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatTexto(texto) {
      const textoSemColchetes = texto.replace(/\[|\]/g, ""); // Remover colchetes
      const textoSemQuebraDeLinha = textoSemColchetes.replace(/\\n/g, " ").replace(/"/g, ""); // Remover caracteres de nova linha (\n) e aspas
      const textoFormatado = textoSemQuebraDeLinha.replace(/\n/g, "<br>"); // Substituir quebras de linha por HTML <br>
      return textoFormatado.trim() || "- - -";
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    localDrawer() {
      this.$emit("input", this.localDrawer);
    },
  },
  computed: {
    localAula() {
      return this.aula;
    },
  },
  data() {
    return {
      localDrawer: this.value,
    };
  },
};
</script>

<style lang="scss" scoped></style>
